import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import NewLoadMedia from "../../Load/NewLoadMedia";

import { cn, username, withPfp } from "~/src/util/reusables";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import { getActiveAccountForPlatform } from "~/src/views/CommunityManagement/lib/utils";
import { PlatformType } from "~/src/util/platforms";
import dayjs from "~/src/util/dayjs";
import { Team } from "~/src/api/team.api";
import ViewOnlyCaptionWithFallback from "../../../views/Post/components/editors/ViewOnlyCaptionWithFallback";

interface TwitterTemplateProps {
  className?: string;
  display?: React.ReactNode;
  media?: string;
  mimeType?: string;
  caption: string;
  selected?: boolean;
  style?: any;
  handle?: string;
  when?: dayjs.Dayjs;
  /**
   * @deprecated use direct `forceDisplayPfp` and `forceDisplayName` instead
   */
  forceWorkspace?: Team;
  forceDisplayPfp?: string;
  forceDisplayName?: string;
  onSelectAccount?: () => void;
}

const TwitterTemplate = ({
  className,
  display,
  media,
  mimeType,
  caption,
  selected,
  style,
  handle,
  when,
  onSelectAccount,
  forceWorkspace,
  forceDisplayPfp,
  forceDisplayName,
}: TwitterTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { name, pfp } = getActiveAccountForPlatform(
    forceWorkspace ?? user?.workspace,
    PlatformType.Twitter,
  );

  return (
    <div
      className={cn(
        "w-full rounded-lg border bg-white text-sm shadow-lg",
        className,
      )}
      style={{ ...style, maxHeight: "100%" }}
    >
      <div className="flex items-start gap-2 rounded-lg bg-white p-2 shadow-md">
        <div className="inline-flex shrink-0 flex-col">
          <img
            crossOrigin="anonymous"
            src={withPfp(
              forceDisplayName ? forceDisplayPfp : pfp,
              forceDisplayName ?? name ?? username(user),
            )}
            alt=""
            className="h-[30px] w-[30px] rounded-full border border-[#efefef] bg-white transition-all duration-150 ease-in"
            onClick={onSelectAccount}
          />
        </div>
        <div className="inline-flex w-auto flex-grow flex-col p-0">
          <div onClick={onSelectAccount}>
            <span className="inline font-bold">
              {forceDisplayName ?? name ?? username(user)}
            </span>{" "}
            {handle && (
              <span
                className="inline text-[#999]"
                data-testid="twitter-post-template-handle"
              >
                @{handle}
              </span>
            )}
            <span className="inline text-[#999]">
              {" "}
              •{" "}
              {when
                ? when.year() === dayjs().year()
                  ? when.format("MMM D")
                  : when.format("MMMM D, YYYY")
                : "Just Now"}
            </span>
          </div>
          <ViewOnlyCaptionWithFallback
            platform={PlatformType.Twitter}
            stringContent={caption}
            className="mb-2 block break-words"
          />
          {!!display && display}
          {!display && media && (
            <NewLoadMedia
              src={media}
              mimeType={mimeType}
              className="block w-full rounded-lg border border-gray-300"
            />
          )}
        </div>
        <BsPatchCheckFill
          width={32}
          height={32}
          className={`absolute -right-4 -top-4 h-8 w-8 text-[var(--color-accent-blue)] transition-all duration-150 ease-in-out ${
            selected ? "opacity-100" : "-top-8 opacity-0"
          }`}
        />
      </div>
    </div>
  );
};

export default TwitterTemplate;
