import { Video } from "lucide-react";
import React, { useState, ComponentPropsWithoutRef, Fragment } from "react";
import { videoTypes } from "~/src/util/loaders";
import { cn } from "~/src/util/reusables";
import { isSafari } from "react-device-detect";
import { useIntersectionObserver } from "usehooks-ts";

type NewLoadMediaProps = {
  alt?: string;
  src: string;
  thumbnail?: string;
  mimeType: string;
  className?: string;
  skeletonClassName?: string;
  showVideoIndicator?: boolean;
  hideVideoControls?: boolean;
} & ComponentPropsWithoutRef<"div">;

/**
 * Built 3/13/24 in response to issues with media sizing within Concept preview...
 */
const NewLoadMedia = ({
  alt,
  src,
  thumbnail,
  mimeType,
  className,
  skeletonClassName,
  showVideoIndicator = false,
  hideVideoControls = false,
  ...props
}: NewLoadMediaProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.1,
    freezeOnceVisible: true,
  });

  return (
    <Fragment key={thumbnail}>
      {isIntersecting &&
        (videoTypes.includes(mimeType) ? (
          <>
            {thumbnail && (
              <img
                loading="eager"
                crossOrigin="anonymous"
                src={thumbnail}
                alt={alt}
                className={cn(
                  "shadow",
                  className ?? "rounded-md",
                  loaded && "hidden",
                )}
              />
            )}
            <video
              alt={alt}
              onCanPlay={() => setLoaded(true)}
              crossOrigin="anonymous"
              className={cn(
                "shadow",
                className ?? "rounded-md ",
                !loaded && "hidden",
              )}
              autoPlay={!isSafari}
              loop={showVideoIndicator}
              playsInline
              muted
              controls={!showVideoIndicator && !hideVideoControls}
              onError={(event) => {
                // Fallback to a placeholder video if loading fails
                (event.target as any).src =
                  "https://flamel-misc.s3.amazonaws.com/video-error-placeholder.mp4";
                (event.target as any).parentElement?.load();
                setLoaded(true);
              }}
              {...(props as any)}
            >
              <source src={src} />
            </video>
          </>
        ) : (
          <img
            alt={alt}
            onLoad={() => setLoaded(true)}
            crossOrigin="anonymous"
            src={thumbnail ?? src}
            className={cn(
              "shadow",
              className ?? "rounded-md",
              !loaded && "hidden",
            )}
            onError={(event) => {
              // Fallback to a placeholder image if loading fails
              (event.target as any).src =
                "https://flamel-misc.s3.amazonaws.com/image-error-placeholder.png";
              setLoaded(true);
            }}
            {...props}
          />
        ))}

      <div
        className={cn(
          loaded
            ? "hidden"
            : `mx-auto h-[200px] w-full animate-pulse bg-gray-300`,
          skeletonClassName ?? "rounded-md",
        )}
        ref={ref}
        {...props}
      />
      {showVideoIndicator && (
        <span className="absolute right-2 top-2 flex min-h-6 min-w-6 items-center justify-center gap-1 rounded-md bg-secondary-foreground/40 p-1">
          <Video className="relative h-4 w-4 text-accent" />
          {!videoTypes.includes(mimeType) && (
            <span className="text-2xs text-white">Preview unavailable.</span>
          )}
        </span>
      )}
    </Fragment>
  );
};

export default NewLoadMedia;
