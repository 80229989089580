import "@pqina/pintura/pintura.css";
import "@pqina/pintura-video/pinturavideo.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./prosemirror.css";
import "./style.css";

import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";

import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import API from "./api/withApi";

import { persistor, store } from "./store";
import { loadFonts } from "./lib/Editor/util/fonts";
import { withManifest } from "./partials/CheckManifest/CheckManifest";

import AppLayout from "./layouts/AppLayout";
import HubLayout from "./layouts/HubLayout";
import AnalyticsLayout from "./layouts/AnalyticsLayout";
import PaidLayout from "./layouts/PaidLayout";

import Load from "./partials/Load/Load";
import Fatal from "./views/Error/Fatal";

// Eagerly loaded auth components
import Login from "./views/Auth/Login";
import Signup from "./views/Auth/Signup";
import ForgotPassword from "./views/Auth/ForgotPassword";
import VerifyEmail from "./views/Auth/VerifyEmail";
import SetPassword from "./views/Auth/SetPassword";

// Paid
const PaidOverview = reactLazyWithRetry(() => import("./views/Paid/Overview"));
const PaidCampaignManage = reactLazyWithRetry(
  () => import("./views/Paid/Campaigns/Manage"),
);
const PaidCampaignList = reactLazyWithRetry(
  () => import("./views/Paid/Campaigns/List"),
);
const PaidAnalytics = reactLazyWithRetry(
  () => import("./views/Paid/Analytics"),
);
const PaidAdsetManage = reactLazyWithRetry(
  () => import("./views/Paid/Adsets/Manage"),
);
const PaidAdManage = reactLazyWithRetry(
  () => import("./views/Paid/Ads/Manage"),
);
const PaidAdCreativesList = reactLazyWithRetry(
  () => import("./views/Paid/Adcreatives/List"),
);
const PaidAdCreativesManage = reactLazyWithRetry(
  () => import("./views/Paid/Adcreatives/Manage"),
);
const PaidAdFormsList = reactLazyWithRetry(
  () => import("./views/Paid/Forms/List"),
);
const PaidAdFormsManage = reactLazyWithRetry(
  () => import("./views/Paid/Forms/Manage"),
);
const ListPaidTargeting = reactLazyWithRetry(
  () => import("./views/Paid/Targeting/List"),
);
const ListPaidAudiences = reactLazyWithRetry(
  () => import("./views/Paid/Audiences/List"),
);
const ListPaidAdLabels = reactLazyWithRetry(
  () => import("./views/Paid/Adlabels/List"),
);

// Lazy loaded components
const OrganicDashboard = reactLazyWithRetry(
  () => import("./views/Dashboard/OrganicDashboard"),
);
const CalendarView = reactLazyWithRetry(() => import("./views/Calendar"));
const Edit = reactLazyWithRetry(() => import("./views/Edit/Edit"));
const Campaigns = reactLazyWithRetry(() => import("./views/Campaigns"));
const PostDiscussion = reactLazyWithRetry(
  () => import("./views/PostDiscussion/PostDiscussion"),
);
const Organization = reactLazyWithRetry(
  () => import("./views/Organization/Organization"),
);
const GuestLayout = reactLazyWithRetry(
  () => import("./views/Guest/GuestLayout"),
);
const GuestCalendar = reactLazyWithRetry(
  () => import("./views/Guest/components/Calendar"),
);
const GuestPosts = reactLazyWithRetry(
  () => import("./views/Guest/components/Posts"),
);
const GuestMedia = reactLazyWithRetry(
  () => import("./views/Guest/components/Media"),
);
const GuestUpload = reactLazyWithRetry(
  () => import("./views/Guest/components/Upload"),
);
const GuestDashboard = reactLazyWithRetry(
  () => import("./views/Guest/components/Dashboard"),
);
const UnifiedInbox = reactLazyWithRetry(
  () => import("./views/CommunityManagement/UnifiedInbox"),
);
const Admin = reactLazyWithRetry(() => import("./views/Admin/Admin"));
const Profile = reactLazyWithRetry(() => import("./views/Account/Profile"));
const Help = reactLazyWithRetry(() => import("./views/Account/Help"));
const Conversion = reactLazyWithRetry(
  () => import("./views/Conversion/Conversion"),
);
const Guides = reactLazyWithRetry(() => import("./views/Guides"));
const SocialOnboarding = reactLazyWithRetry(
  () => import("./views/SocialOnboarding"),
);
const Library = reactLazyWithRetry(() => import("./views/Library/Library"));
const Drafts = reactLazyWithRetry(() => import("./views/Drafts/Drafts"));
const ChainLayout = reactLazyWithRetry(
  () => import("./views/Chain/ChainLayout"),
);
const CampaignDetail = reactLazyWithRetry(
  () => import("./views/Campaigns/CampaignDetail"),
);
const PostDetail = reactLazyWithRetry(() => import("./views/Post/PostDetail"));
const DraftDetail = reactLazyWithRetry(
  () => import("./views/Post/DraftDetail"),
);
const Posts = reactLazyWithRetry(
  () => import("./views/CommunityManagement/Posts"),
);
const PostManagement = reactLazyWithRetry(
  () => import("./views/CommunityManagement/PostManagement"),
);
const AccessDenied = reactLazyWithRetry(
  () => import("./views/CommunityManagement/AccessDenied"),
);
const CampaignSpawner = reactLazyWithRetry(
  () => import("./views/Campaigns/CampaignSpawner"),
);
const CommentManagement = reactLazyWithRetry(
  () => import("./views/CommunityManagement/CommentManagement"),
);
const Billing = reactLazyWithRetry(
  () => import("./views/Subscription/Billing"),
);
const WorkspaceBrand = reactLazyWithRetry(() => import("./views/Brand"));
const Socials = reactLazyWithRetry(() => import("./views/Socials/Socials"));
const IntegrationsView = reactLazyWithRetry(
  () => import("./views/Integrations"),
);
const Error404 = reactLazyWithRetry(() => import("./views/Error/404"));

// Analytics components
const Overview = reactLazyWithRetry(() => import("./views/Analytics/Overview"));
const Audience = reactLazyWithRetry(() => import("./views/Analytics/Audience"));
const Activity = reactLazyWithRetry(() => import("./views/Analytics/Activity"));
const Impressions = reactLazyWithRetry(
  () => import("./views/Analytics/Impressions"),
);
const Engagement = reactLazyWithRetry(
  () => import("./views/Analytics/Engagement"),
);
const ByLocation = reactLazyWithRetry(
  () => import("./views/Analytics/ByLocation"),
);
const Content = reactLazyWithRetry(() => import("./views/Analytics/Content"));

// Hub components
const HubMedia = reactLazyWithRetry(
  () => import("./views/Hubs/components/Media"),
);
const Concepts = reactLazyWithRetry(() => import("./views/Concepts"));
const Templates = reactLazyWithRetry(() => import("./views/Templates"));
const Brand = reactLazyWithRetry(() => import("./views/Brand"));
const BroadcastEditor = reactLazyWithRetry(
  () => import("./views/Hubs/components/Locations/BroadcastEditor"),
);
const ConceptCalendar = reactLazyWithRetry(
  () => import("./views/Hubs/components/ConceptCalendar"),
);
const LocationsTable = reactLazyWithRetry(
  () => import("./views/Hubs/components/Locations/LocationsTable"),
);
const HubSettings = reactLazyWithRetry(
  () => import("./views/Hubs/components/HubSettings"),
);
const Leaderboard = reactLazyWithRetry(
  () => import("./views/Hubs/components/Leaderboard"),
);

declare global {
  interface Window {
    env: any;
  }
}
window.env = window.env || {};
export const queryClient = new QueryClient();

function RouterErrorBoundary() {
  const error = useRouteError();
  console.dir(error, { depth: null });
  return <Fatal error={error} />;
}

const paidRoutes = {
  path: "paid",
  element: <PaidLayout />,
  children: [
    { index: true, element: <PaidOverview /> },
    {
      path: "campaigns",
      children: [
        { index: true, element: <PaidCampaignList /> },
        {
          path: "create",
          element: <PaidCampaignManage key="create" />,
        },
        {
          path: ":campaignId",
          children: [
            { index: true, element: <PaidCampaignManage /> },
            {
              path: "adsets",
              children: [
                {
                  path: "create",
                  element: <PaidAdsetManage key="create" />,
                },
                {
                  path: ":adsetId",
                  children: [
                    { index: true, element: <PaidAdsetManage /> },
                    {
                      path: "ads",
                      children: [
                        {
                          path: "create",
                          element: <PaidAdManage key="create" />,
                        },
                        { path: ":adId", element: <PaidAdManage /> },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "adcreatives",
      children: [
        { index: true, element: <PaidAdCreativesList /> },
        {
          path: "create",
          element: <PaidAdCreativesManage key="create" />,
        },
        { path: ":adCreativeId", element: <PaidAdCreativesManage /> },
      ],
    },
    {
      path: "forms",
      children: [
        { index: true, element: <PaidAdFormsList /> },
        {
          path: "create",
          element: <PaidAdFormsManage key="create" />,
        },
        { path: ":formId", element: <PaidAdFormsManage /> },
      ],
    },
    {
      path: "targeting",
      children: [
        {
          index: true,
          element: <ListPaidTargeting className="overflow-y-auto pt-16" />,
        },
      ],
    },
    {
      path: "adlabels",
      children: [{ index: true, element: <ListPaidAdLabels /> }],
    },
    {
      path: "audiences",
      children: [{ index: true, element: <ListPaidAudiences /> }],
    },
    { path: "analytics", element: <PaidAnalytics /> },
    { path: "socials", element: <Socials /> },
    { path: "help", element: <Help /> },
    { path: "profile", element: <Profile {...API} /> },
  ],
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      { index: true, element: <OrganicDashboard /> },
      {
        path: "hub/:hubId",
        element: <HubLayout />,
        children: [
          { index: true, element: <OrganicDashboard /> },
          { path: "media", element: <HubMedia /> },
          { path: "concepts", element: <Concepts /> },
          { path: "templates", element: <Templates canManageItems /> },
          { path: "brand", element: <Brand /> },
          { path: "drafts/:portfolioId", element: <BroadcastEditor /> },
          { path: "design", element: <Edit /> },
          { path: "design/template/:templateId", element: <Edit /> },
          { path: "calendar", element: <ConceptCalendar /> },
          {
            path: "drafts",
            element: (
              <Drafts
                onEdit={(pfi, navigate, hubId) =>
                  navigate(`/hub/${hubId}/drafts/${pfi._id}`)
                }
              />
            ),
          },
          {
            path: "analytics",
            element: <AnalyticsLayout />,
            children: [
              { index: true, element: <Overview /> },
              { path: "audience", element: <Audience /> },
              { path: "activity", element: <Activity /> },
              { path: "impressions", element: <Impressions /> },
              { path: "engagement", element: <Engagement /> },
              { path: "locations", element: <ByLocation /> },
              { path: "content", element: <Content /> },
              { path: "leaderboard", element: <Leaderboard canRefresh /> },
            ],
          },
          { path: "locations", element: <LocationsTable /> },
          { path: "settings", element: <HubSettings /> },
          { path: "socials", element: <Socials /> },
          paidRoutes,
        ],
      },
      { path: "socials", element: <Socials /> },
      { path: "integrations", element: <IntegrationsView /> },
      { path: "calendar", element: withManifest(<CalendarView />, "calendar") },
      { path: "media", element: withManifest(<Library />, "view_media") },
      { path: "concepts", element: <Concepts /> },
      {
        path: "drafts",
        element: withManifest(
          <Drafts onEdit={(pfi, navigate) => navigate(`/drafts/${pfi._id}`)} />,
          "view_media",
        ),
      },
      {
        path: "posts",
        element: withManifest(<Posts />, "view_media", <AccessDenied />),
      },
      {
        path: "post/:postId",
        element: withManifest(<PostManagement />, "view_media"),
      },
      { path: "engage", element: <CommentManagement /> },
      { path: "design", element: <Edit /> },
      { path: "design/template/:templateId", element: <Edit /> },
      { path: "templates", element: <Templates canManageItems /> },
      { path: "campaigns", element: withManifest(<Campaigns />, "campaigns") },
      {
        path: "campaigns/spawn",
        element: withManifest(<CampaignSpawner />, "campaigns"),
      },
      {
        path: "campaigns/:campaignId",
        element: withManifest(<CampaignDetail />, "campaigns"),
      },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "audience", element: <Audience /> },
          { path: "activity", element: <Activity /> },
          { path: "impressions", element: <Impressions /> },
          { path: "engagement", element: <Engagement /> },
          { path: "content", element: <Content /> },
          { path: "leaderboard", element: <Leaderboard /> },
        ],
      },
      { path: "profile", element: <Profile {...API} /> },
      { path: "help", element: <Help /> },
      { path: "billing", element: withManifest(<Billing />, "subscription") },
      {
        path: "organization",
        element: withManifest(<Organization />, "view_organization"),
      },
      { path: "post/discussion/:larvalid", element: <PostDiscussion /> },
      {
        path: "drafts/:portfolioId",
        element: withManifest(<DraftDetail />, "edit"),
      },
      {
        path: "post/:postId/edit",
        element: withManifest(<PostDetail />, "edit"),
      },
      { path: "brain", element: <ChainLayout /> },
      {
        path: "inbox",
        element: withManifest(<UnifiedInbox />, "inbox", <AccessDenied />),
      },
      { path: "brand", element: <WorkspaceBrand /> },
      paidRoutes,
      // GRAVE YARD ☠️ ------------------------------
      /**
       * @deprecated - 11/08/2024 - No longer relevant, but a fun Easter Egg 🥚
       */
      { path: "guides", element: <Guides /> },
      // ----------------------------------------------
      { path: "*", element: <Error404 /> },
    ],
  },
  { path: "/login", element: <Login {...API} /> },
  { path: "/signup", element: <Signup {...API} /> },
  { path: "/welcome", element: <SocialOnboarding /> },
  { path: "/forgot-password", element: <ForgotPassword {...API} /> },
  { path: "/verify-email", element: <VerifyEmail /> },
  { path: "/set-password", element: <SetPassword /> },
  {
    path: "/guest",
    element: <GuestLayout />,
    children: [
      { index: true, element: <GuestDashboard /> },
      { path: "calendar", element: <GuestCalendar /> },
      { path: "posts", element: <GuestPosts /> },
      { path: "media", element: <GuestMedia /> },
      { path: "upload", element: <GuestUpload /> },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "audience", element: <Audience /> },
          { path: "activity", element: <Activity /> },
          { path: "impressions", element: <Impressions /> },
          { path: "engagement", element: <Engagement /> },
        ],
      },
      { path: "posts/:larvalid/discussion", element: <PostDiscussion /> },
      paidRoutes,
      { path: "*", element: <Error404 /> },
    ],
  },
  { path: "/admin", element: withManifest(<Admin />, "admin") },
  { path: "/conversion", element: <Conversion /> },
  { path: "*", element: <Error404 /> },
]);

const App = () => {
  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fallbackRender={({ error }) => <Fatal error={error} />}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Load />}>
              <RouterProvider router={router} fallbackElement={<Load />} />
            </Suspense>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

createRoot(document.getElementById("root")).render(<App />);

export default App;
