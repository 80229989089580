import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign, LarvalPost, PortfolioItem } from "../api/withApi";
import type { PlatformType } from "../util/platforms";

export type Presets = {
  style: string;
  audience: string[];
  tone: string;
};

export type ToolkitStateSlice = {
  campaign?: Campaign;
  loading?: string;
  pendingRequestController?: AbortController;
  success?: string;
  error?: string;
  presets: Presets;
  prompt?: string;
  unconnectedPlatform?: PlatformType;
  // Post Data
  pendingTimestamp?: number;
  portfolioItem?: PortfolioItem;
  larvalPost?: LarvalPost;
  withPlatform?: PlatformType;
  withPlatformAccountId?: string | undefined;
  withPlatformAccountPfp?: string | undefined;
  withPlatformAccountName?: string | undefined;
};

const initialState: ToolkitStateSlice = {
  campaign: undefined,
  loading: undefined,
  pendingRequestController: undefined,
  success: undefined,
  error: undefined,
  presets: {
    style: undefined,
    audience: [],
    tone: undefined,
  },
  prompt: undefined,
  pendingTimestamp: undefined,
  unconnectedPlatform: undefined,
  portfolioItem: undefined,
  larvalPost: undefined,
  withPlatform: "facebook",
  withPlatformAccountId: undefined,
  withPlatformAccountPfp: undefined,
  withPlatformAccountName: undefined,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { loading, success, error, ...initialStateWithoutStatus } = initialState;

const toolkitSlice = createSlice({
  name: "toolkit",
  initialState,
  reducers: {
    setPendingTimestamp(state, action: PayloadAction<number>) {
      return { ...state, pendingTimestamp: action.payload };
    },
    setCampaign(state, action: PayloadAction<Campaign>) {
      return { ...state, campaign: action.payload };
    },
    setLoading(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setPendingRequestController(state, action: PayloadAction<AbortController>) {
      return {
        ...state,
        pendingRequestController: action.payload,
      };
    },
    setSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: undefined,
        success: action.payload,
      };
    },
    setError(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: undefined,
        error: action.payload,
      };
    },
    setPresets(state, action: PayloadAction<Presets>) {
      return { ...state, presets: action.payload };
    },
    setPrompt(state, action: PayloadAction<string>) {
      return { ...state, prompt: action.payload };
    },
    setUnconnectedPlatform(state, action: PayloadAction<PlatformType>) {
      return { ...state, unconnectedPlatform: action.payload };
    },
    setPortfolioItem(state, action: PayloadAction<PortfolioItem>) {
      return {
        ...state,
        portfolioItem: action.payload,
        withPlatform: action.payload?.platform ?? state.withPlatform,
      };
    },
    setLarvalPost(state, action: PayloadAction<LarvalPost>) {
      return {
        ...state,
        larvalPost: action.payload,
        pendingTimestamp:
          action.payload?.scheduled?.timestamp ?? state.pendingTimestamp,
        withPlatform: action.payload?.platform ?? state.withPlatform,
      };
    },
    setWithPlatform(state, action: PayloadAction<PlatformType>) {
      return { ...state, withPlatform: action.payload };
    },
    resetPlatformState(state) {
      return { ...state, ...initialStateWithoutStatus };
    },
  },
});

const { actions, reducer } = toolkitSlice;
export const {
  setPendingTimestamp,
  setCampaign,
  setLoading,
  setPendingRequestController,
  setSuccess,
  setError,
  setPresets,
  setPrompt,
  setUnconnectedPlatform,
  setPortfolioItem,
  setLarvalPost,
  setWithPlatform,
  resetPlatformState,
} = actions;
export default reducer;
