import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./instagram-template.css";
import NewLoadMedia from "../../Load/NewLoadMedia";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import { cn, withPfp, username as getUsername } from "~/src/util/reusables";
import { getActiveAccountForPlatform } from "~/src/views/CommunityManagement/lib/utils";
import { PlatformType } from "~/src/util/platforms";
import { InstagramPost, Team } from "~/src/api/withApi";

export interface InstagramStoryTemplateProps {
  className?: string;
  display?: React.ReactNode;
  media?: string;
  mimeType?: string;
  selected?: boolean;
  style?: any;
  onSelectAccount?: () => void;
  raw?: InstagramPost;
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
}

const InstagramStoryTemplate = ({
  className,
  display,
  media,
  mimeType,
  selected,
  style,
  onSelectAccount,
  raw,
  forceWorkspace,
  forceDisplayPfp,
  forceDisplayName,
}: InstagramStoryTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { name: username, pfp: profile_picture_url } =
    getActiveAccountForPlatform(
      forceWorkspace ?? user?.workspace,
      PlatformType.Instagram,
    );

  const { owner } = raw ?? {};
  const { profile_picture_url: dynamicPfp, username: dynamicUsername } =
    owner ?? {};

  return (
    <div
      className={cn(
        "max-h-full min-h-[200px] w-auto animate-fadein overflow-hidden rounded-lg border border-[#efefef] bg-white shadow-md",
        className,
      )}
    >
      <div
        className="relative flex aspect-[9/16] h-full min-h-max w-full max-w-sm flex-col items-center justify-center overflow-y-auto bg-black"
        style={style ?? {}}
      >
        {!!display && display}
        {!display && media && (
          <NewLoadMedia
            mimeType={mimeType}
            src={media}
            className="w-full rounded-lg"
          />
        )}
        <div className="absolute top-0 flex w-full justify-between gap-2">
          <div
            className={cn(
              "space-2 flex flex-col justify-end",
              display || media ? "text-white" : "text-black",
            )}
          >
            <div className="grid gap-2 whitespace-pre-wrap p-3">
              <div className="flex items-center" onClick={onSelectAccount}>
                <img
                  crossOrigin="anonymous"
                  src={withPfp(
                    forceDisplayName
                      ? forceDisplayPfp
                      : dynamicPfp ?? profile_picture_url,
                    forceDisplayName ??
                      dynamicUsername ??
                      username ??
                      getUsername(user),
                  )}
                  alt=""
                  className="mr-2.5 inline-flex h-8 w-8 rounded-full bg-white align-middle"
                />
                <div className="inline-flex align-middle text-sm font-semibold">
                  {forceDisplayName ??
                    dynamicUsername ??
                    username ??
                    getUsername(user)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <BsPatchCheckFill
          width={40}
          height={40}
          className={`selected-checkmark ${selected ? "" : "hidden"}`}
        />
      </div>
    </div>
  );
};

export default InstagramStoryTemplate;
