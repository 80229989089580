import { useDispatch, useSelector } from "react-redux";
import API, {
  OverridesForPlatform,
  type Campaign,
  type LarvalPost,
  type Media,
  type PortfolioItem,
  type Post,
} from "~/src/api/withApi";
import { Event } from "~/src/lib/Calendar/Calendar";
import {
  Presets,
  setLarvalPost,
  setPortfolioItem,
} from "~/src/reducers/toolkit";
import { RootState } from "~/src/store";
import { PlatformType } from "../../util/platforms";
import { Tag } from "./components/ImageTagger";

export default function useWorkingItem() {
  const dispatch = useDispatch();
  const portfolioItem = useSelector(
    (state: RootState) => state.toolkit.portfolioItem,
  );
  const larvalPost = useSelector(
    (state: RootState) => state.toolkit.larvalPost,
  );

  // Check if we have the data needed to proceed.
  if (!portfolioItem && !larvalPost) return {};

  const workingItem = portfolioItem ?? larvalPost;
  const isWorkingItemLarval =
    workingItem?._id === larvalPost?._id &&
    !(workingItem as LarvalPost)?.broadcastParentHub;

  const setWorkingItem = (item: PortfolioItem | LarvalPost) =>
    isWorkingItemLarval
      ? dispatch(setLarvalPost(item as LarvalPost))
      : dispatch(setPortfolioItem(item));

  const getWorkingItem = (itemId: string) =>
    isWorkingItemLarval
      ? API.getScheduledPost(itemId)
          .then((data) => data.json())
          .then((p) => {
            const { media, ...rest } = p;
            return setWorkingItem({
              ...rest,
              media: media?.map(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({ jpguri, thumbnail, ...restMedia }) => ({
                  ...restMedia,
                  thumbnail: thumbnail
                    ? `${thumbnail}?${Date.now()}`
                    : undefined,
                }),
              ),
            });
          })
      : API.getPortfolioItem(itemId).then(
          (p: PortfolioItem & { media: Media[] }) => {
            const { media, ...rest } = p;
            return setWorkingItem({
              ...rest,
              media: media?.map(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({ jpguri, thumbnail, ...restMedia }) => ({
                  ...restMedia,
                  thumbnail: thumbnail
                    ? `${thumbnail}?${Date.now()}`
                    : undefined,
                }),
              ),
            });
          },
        );
  const updateWorkingItem = (
    itemId: string,
    updates: Partial<PortfolioItem | LarvalPost>,
  ) =>
    isWorkingItemLarval
      ? API.updateScheduledPost(
          itemId,
          {
            ...(updates as Partial<LarvalPost>),
          },
          false, // Allows saving post updates, without trying to just post it right away...
        )
          .then((data) => data.json())
          .then(setWorkingItem)
      : API.updatePortfolioItem(itemId, updates)
          .then((data) => data.json())
          .then(setWorkingItem);
  const updateWorkingItemMedia = (_id: string, mediaIds: string[]) =>
    isWorkingItemLarval
      ? API.setLarvalPostMedia(_id, mediaIds)
          .then((data) => data.json())
          .then(setWorkingItem)
      : API.setPortfolioItemMedia(_id, mediaIds)
          .then((data) => data.json())
          .then(setWorkingItem);

  const updateWorkingItemMetadataForPlatform = (
    _id: string,
    platform: PlatformType,
    overrides: OverridesForPlatform | null,
  ) =>
    isWorkingItemLarval
      ? API.updateScheduledPost(
          _id,
          {
            [`overrides.${platform}`]: overrides,
          },
          !(workingItem as LarvalPost)?.isDraft,
        )
          .then((data) => data.json())
          .then(setWorkingItem)
      : API.updatePortfolioItem(_id, { [`overrides.${platform}`]: overrides })
          .then((data) => data.json())
          .then(setWorkingItem);

  const updateWorkingItemAltTextForMedia = (
    _id: string,
    media_id: string,
    alt: string,
  ) =>
    isWorkingItemLarval
      ? API.updateScheduledPost(
          _id,
          {
            [`alt.${media_id}`]: alt,
          },
          !(workingItem as LarvalPost)?.isDraft,
        )
          .then((data) => data.json())
          .then(setWorkingItem)
      : API.updatePortfolioItem(_id, { [`alt.${media_id}`]: alt })
          .then((data) => data.json())
          .then(setWorkingItem);

  const updateWorkingItemPlatformTagForMedia = (
    _id: string, // Working Item _id
    platform: PlatformType,
    media_id: string,
    tag: Tag,
  ) =>
    isWorkingItemLarval
      ? API.updateScheduledPost(
          _id,
          {
            [`accountTags.${media_id}.${platform}`]: tag,
          },
          !(workingItem as LarvalPost)?.isDraft,
        )
          .then((data) => data.json())
          .then(setWorkingItem)
      : API.updatePortfolioItem(_id, {
          [`accountTags.${media_id}.${platform}`]: tag,
        })
          .then((data) => data.json())
          .then(setWorkingItem);

  return {
    workingItem,
    isWorkingItemLarval,
    setWorkingItem,
    getWorkingItem,
    updateWorkingItem,
    updateWorkingItemMedia,
    updateWorkingItemMetadataForPlatform,
    updateWorkingItemAltTextForMedia,
    updateWorkingItemPlatformTagForMedia,
  };
}

type PostProperties = {
  name?: string;
  topics?: string[];
  caption?: string;
  media?: Media[];
  platformContentType: string;
  prompt?: string;
  presets?: Presets;
  campaign?: Campaign;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
};

/**
 *
 * @param item
 * @param platform
 * @param isWorkingItemLarval - VERY IMPORTANT!!! - without this, there can be false fallbacks to portfolio item media when not expected
 */
export const getPostProperties = (
  item: PortfolioItem | LarvalPost | Event | Post,
  platform?: PlatformType,
  isWorkingItemLarval?: boolean,
): PostProperties => {
  if (isWorkingItemLarval) {
    const larvalItem = item as LarvalPost;
    return {
      caption:
        larvalItem.overrides?.[platform]?.caption ?? larvalItem.caption ?? "",
      media:
        !larvalItem.media?.length && larvalItem.portfolioItem?.campaign
          ? (larvalItem.portfolioItem?.media as Media[]) ?? []
          : larvalItem.media ?? [],
      platformContentType:
        larvalItem.overrides?.[platform]?.platformContentType ??
        larvalItem.platformContentType ??
        "post",
      prompt: larvalItem.overrides?.[platform]?.prompt ?? larvalItem.prompt,
      presets: larvalItem.overrides?.[platform]?.presets ?? larvalItem.presets,

      // Portfolio related
      name:
        (item as PortfolioItem)?.name ??
        (item as LarvalPost)?.portfolioItem?.name ??
        "",

      topics:
        (item as PortfolioItem)?.topics ??
        (item as LarvalPost)?.portfolioItem?.topics ??
        [],
      campaign: (item?.campaign ??
        (item as LarvalPost)?.portfolioItem?.campaign) as Campaign,
      accountTags:
        (item as LarvalPost)?.portfolioItem?.accountTags ??
        (item as PortfolioItem)?.accountTags ??
        ({} as Record<PlatformType, Record<string, Tag[]>>),
    };
  }

  const name =
    (item as PortfolioItem)?.name ??
    (item as LarvalPost)?.portfolioItem?.name ??
    "";

  const topics =
    (item as PortfolioItem)?.topics ??
    (item as LarvalPost)?.portfolioItem?.topics ??
    [];

  // [Backwards Compatability - 4/4/24] - fall back to parent pfi
  const campaign = (item?.campaign ??
    (item as LarvalPost)?.portfolioItem?.campaign) as Campaign;
  const media = ((item?.media?.length
    ? item.media
    : (item as LarvalPost)?.portfolioItem?.media) ?? []) as Media[];
  const caption =
    ((item as PortfolioItem)?.overrides?.[platform]?.caption ||
      item?.caption ||
      (item as LarvalPost)?.portfolioItem?.caption) ??
    "";

  const platformContentType =
    (item as LarvalPost)?.overrides?.[platform]?.platformContentType ??
    (item as PortfolioItem)?.overrides?.[platform]?.platformContentType ??
    (item as LarvalPost)?.platformContentType ??
    (item as LarvalPost)?.portfolioItem?.platformContentType ??
    "post";

  const prompt =
    (item as PortfolioItem)?.overrides?.[platform]?.prompt ??
    item?.prompt ??
    (item as LarvalPost)?.portfolioItem?.prompt;
  const presets =
    (item as PortfolioItem)?.overrides?.[platform]?.presets ??
    (item as unknown as LarvalPost)?.presets ??
    (item as LarvalPost)?.portfolioItem?.presets;

  const accountTags =
    (item as LarvalPost)?.portfolioItem?.accountTags ??
    (item as PortfolioItem)?.accountTags ??
    ({} as Record<PlatformType, Record<string, Tag[]>>);

  return {
    name,
    topics,
    caption,
    media,
    platformContentType,
    prompt,
    presets,
    campaign,
    accountTags,
  };
};
