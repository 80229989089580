import axios from "axios";
import { ObjectValues, withQueryParams } from "../util/reusables";

export interface Media {
  _id?: string;
  userAccount?: string;
  portfolioItems?: string[];
  team?: string;
  hub?: string;
  name?: string;
  uri?: string;
  alt?: string;
  thumbnail?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
  editUri?: string;
  mimeType?: string;
  jpguri?: string;
  editorState?: string;
  from?: MediaSource;
  tags?: string[];
  path?: string[];
  asTemplate?: boolean; // Is the media used for a template
}

export const MediaSource = {
  AI: "ai",
  Upload: "upload",
  Stock: "stock",
  Editor: "editor",
  Sticker: "sticker",
  Giphy: "giphy",
  Canva: "canva",
  Post: "post",
  Hub: "hub",
  Broadcast: "broadcast",
} as const;
export type MediaSource = ObjectValues<typeof MediaSource>;

const createMedia = (
  file: Blob,
  from: MediaSource,
  options: {
    name?: string;
    tags?: string[];
    alt?: string;
    asTemplate?: boolean;
    path?: string[];
    hubId?: string;
    brandId?: string;
    mimeType?: string;
    editorState?: string;
  } = {},
) => {
  const fed = new FormData();
  fed.append("file", file);

  return fetch(withQueryParams(`/api/media`, { from, ...options }), {
    method: "POST",
    body: fed,
  });
};

const createMediaFromExisting = (from: string, media: Partial<Media>) =>
  fetch(`/api/media/from-existing?from=${from}`, {
    method: "POST",
    body: JSON.stringify({
      ...media,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateMedia = (mediaId: string, diff: Partial<Media>) =>
  fetch(`/api/media/${mediaId}`, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });

const createMediaThumbnail = (
  mediaId: string,
  thumbnail: Blob,
  height: number,
  width: number,
) => {
  const fed = new FormData();
  fed.append("file", thumbnail);

  return fetch(
    withQueryParams(`/api/media/thumbnail`, {
      mediaId,
      height: height.toString(),
      width: width.toString(),
    }),
    {
      method: "PATCH",
      body: fed,
    },
  );
};

const createMediaJpeg = (mediaId: string, jpeg: Blob) => {
  const fed = new FormData();
  fed.append("file", jpeg);

  return fetch(`/api/media/jpeg?mediaId=${mediaId}`, {
    method: "PATCH",
    body: fed,
  });
};

const createMediaEdit = (mediaId: string, edit: Blob) => {
  const fed = new FormData();
  fed.append("file", edit);

  return fetch(`/api/media/edit?mediaId=${mediaId}`, {
    method: "PATCH",
    body: fed,
  });
};

const getMedia = async (hubId?: string) => {
  const mediaUrl = new URL("/api/media", window.location.origin);
  if (hubId) mediaUrl.searchParams.set("hubId", hubId);
  return (await axios.get(mediaUrl?.toString()))?.data;
};
const getMediaById = async (mediaId: string) =>
  (await axios.get(`/api/media/${mediaId}`)).data;

const deleteMedia = (mediaId: string) =>
  fetch(`/api/media/${mediaId}`, {
    method: "DELETE",
  });

const createPassthrough = (uri: string) => `/api/media/passthrough?uri=${uri}`;
const passthrough = (uri: string) => fetch(createPassthrough(uri));

const deleteMediaFolder = async (
  mediaIds: string[],
  pseudoPaths: string[][],
  hubId?: string,
) => {
  const params = new URLSearchParams();
  if (hubId) params.append("hubId", hubId);
  mediaIds.forEach((id) => params.append("mediaId", id));
  pseudoPaths.forEach((pseudo) => params.append("pseudo", pseudo.join("@@@")));

  const response = await axios.delete("/api/media/bulk", {
    params,
    paramsSerializer: (params) => params.toString(),
  });

  return response.data;
};

export interface MediaAPI {
  createMedia: (
    file: Blob,
    from: string,
    options: {
      name?: string;
      tags?: string[];
      alt?: string;
      asTemplate?: boolean;
      path?: string[];
      hubId?: string;
      brandId?: string;
      mimeType?: string;
      editorState?: string;
    },
  ) => Promise<Response>;
  createMediaFromExisting: (
    from: string,
    media: Partial<Media>,
  ) => Promise<Response>;
  updateMedia: (mediaId: string, diff: Partial<Media>) => Promise<Response>;
  createMediaThumbnail: (
    mediaId: string,
    thumbnail: Blob,
    height: number,
    width: number,
  ) => Promise<Response>;
  createMediaJpeg: (mediaId: string, jpeg: Blob) => Promise<Response>;
  createMediaEdit: (mediaId: string, edit: Blob) => Promise<Response>;
  getMedia: (hubId?: string) => Promise<Media[]>;
  getMediaById: (mediaId: string) => Promise<Media>;
  deleteMedia: (mediaId: string) => Promise<Response>;
  createPassthrough: (uri: string) => string;
  passthrough: (uri: string) => Promise<Response>;
  deleteMediaFolder: (
    mediaIds: string[],
    pseudoPaths: string[][],
    hubId?: string,
  ) => Promise<Response>;
}

export default {
  createMedia,
  createMediaFromExisting,
  updateMedia,
  createMediaThumbnail,
  createMediaJpeg,
  createMediaEdit,
  getMedia,
  getMediaById,
  deleteMedia,
  createPassthrough,
  passthrough,
  deleteMediaFolder,
} as MediaAPI;
