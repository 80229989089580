import React from "react";
import { NavigateFunction } from "react-router-dom";
import { CgPexels } from "react-icons/cg";
import {
  BadgeCheck,
  BarChart,
  Brush,
  Building2,
  Cable,
  CalendarRange,
  CircleHelp,
  CreditCard,
  Crown,
  FileText,
  Flame,
  FolderTree,
  FolderUp,
  GanttChart,
  HeartHandshake,
  Home,
  Image,
  Inbox,
  LayoutDashboard,
  LayoutTemplate,
  Lightbulb,
  MapPin,
  MapPinned,
  Megaphone,
  MessagesSquare,
  Newspaper,
  Palette,
  PencilRuler,
  PieChart,
  RadioTowerIcon,
  Settings,
  Settings2,
  Sparkles,
  SquareUserRound,
  Trophy,
  UsersRound,
} from "lucide-react";

import { Module, UserAccount } from "./api/auth.api";
import GiphyIcon from "./assets/GiphyIcon";
import API from "~/src/api/withApi";
import { getPrimaryHubIdForWorkspace } from "./api/hub.api";

export interface AccountRoute {
  to?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  badge?: string;
  badgeClassName?: string;
  routes?: Record<string, AccountRoute>;
}

export const Account = (user: UserAccount): AccountRoute => ({
  routes: {
    Profile: {
      to: "/profile",
      icon: <SquareUserRound className="mr-2 h-4 w-4" />,
    },
    Help: {
      to: "/help",
      icon: <CircleHelp className="mr-2 h-4 w-4" />,
    },
    ...(user.manifest.subscription && {
      Billing: {
        to: "/billing",
        icon: <CreditCard className="mr-2 h-4 w-4" />,
      },
    }),
    ...(user.manifest.admin && {
      Admin: {
        to: "/admin",
        icon: <Crown className="mr-2 h-4 w-4 text-yellow-500" />,
      },
    }),
  },
});

export interface MainRoute {
  to?: string;
  isActive?: (pathname: string, search: string) => boolean;
  onClick?: () => Promise<void> | void;
  icon?: React.ReactElement;
  disabled?: boolean;
  badge?: string;
  badgeClassName?: string;
  routes?: Array<Record<string, string | boolean | MainRoute>>;
}

export const url = (
  user: UserAccount | undefined,
  path: string,
  searchParams?: URLSearchParams,
  asRelative?: boolean,
) => {
  const isGuest = user?.pseudo;
  const hubId = user?.hub?._id;
  const currentUrl = new URL(window.location.href);
  const newUrl = new URL(
    isGuest ? `guest${path}` : hubId ? `hub/${hubId}${path}` : path,
    currentUrl.origin,
  );

  const mergedParams = new URLSearchParams(currentUrl.search);
  searchParams?.forEach((value, key) => mergedParams.set(key, value));
  newUrl.search = mergedParams.toString();

  return asRelative ? `${newUrl.pathname}${newUrl.search}` : newUrl.toString();
};

export const removeHubPrefix = (pathname: string) => {
  return pathname.replace(/^\/hub\/[^/]+/, "");
};

const Main = (
  user: UserAccount,
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
): Record<string, MainRoute> => {
  const primaryHubId = getPrimaryHubIdForWorkspace(user.workspace);
  const isHubUser = Boolean(user?.hub?._id);

  const ORGANIZE_MENU: Record<string, MainRoute> = {
    Organize: {
      icon: <FolderTree className="mr-2 h-4 w-4" />,
      isActive: (pathname, search) => {
        const paths = ["/concepts", "/templates"];
        const searchParams = new URLSearchParams(search);
        const cleanPath = removeHubPrefix(pathname);
        return (
          paths.some((path) => cleanPath.startsWith(path)) ||
          (cleanPath === "/media" &&
            ["library", "upload", "pexels", "giphy"].includes(
              searchParams.get("tab"),
            ))
        );
      },
      routes: [
        {
          Media: {
            to: url(user, "/media", new URLSearchParams({ tab: "library" })),
            icon: <Image className="mr-2 h-4 w-4" />,
          },
          Concepts: {
            to: url(user, "/concepts"),
            icon: <Lightbulb className="mr-2 h-4 w-4" />,
          },
          Templates: {
            to: url(user, "/templates"),
            icon: <LayoutTemplate className="mr-2 h-4 w-4" />,
          },
        },
        {
          title: "Quick add...",
          withSeparator: true,
          Upload: {
            to: url(user, "/media", new URLSearchParams({ tab: "upload" })),
            icon: <FolderUp className="mr-2 h-4 w-4" />,
          },
          ["Pexels Stock"]: {
            to: url(user, "/media", new URLSearchParams({ tab: "pexels" })),
            icon: <CgPexels className="mr-2 h-4 w-4" />,
          },
          Giphy: {
            to: url(user, "/media", new URLSearchParams({ tab: "giphy" })),
            icon: <GiphyIcon className="mr-2 h-4 w-4" />,
          },
        },
      ],
    },
  };

  const organicRoutes: Record<string, MainRoute> = {
    ...ORGANIZE_MENU,
    Create: {
      icon: <Brush className="mr-2 h-4 w-4" />,
      isActive: (pathname, search) => {
        const cleanPath = removeHubPrefix(pathname);
        return (
          cleanPath.startsWith("/drafts/") ||
          cleanPath === "/design" ||
          (cleanPath === "/media" &&
            new URLSearchParams(search).get("tab") === "generate")
        );
      },
      routes: [
        {
          title: "Post",
          [isHubUser ? "New Broadcast" : "New Post"]: {
            onClick: async () => {
              const blank = await API.createPortfolioItem({}).then((data) =>
                data.json(),
              );
              navigate(url(user, `/drafts/${blank._id}`, undefined, true));
            },
            icon: isHubUser ? (
              <RadioTowerIcon className="mr-2 h-4 w-4" />
            ) : (
              <Newspaper className="mr-2 h-4 w-4" />
            ),
          },
          ["AI Caption Generator"]: {
            onClick: async () => {
              const blank = await API.createPortfolioItem({}).then((data) =>
                data.json(),
              );
              navigate(
                url(
                  user,
                  `/drafts/${blank._id}`,
                  new URLSearchParams({ ctab: "ai", att: "caption" }),
                  true,
                ),
              );
            },
            icon: <Sparkles className="mr-2 h-4 w-4" />,
          },
        },
        {
          title: "Creative",
          withSeparator: true,
          ["New Design"]: {
            to: url(user, "/design"),
            icon: <PencilRuler className="mr-2 h-4 w-4" />,
          },
          ["AI Image Generator"]: {
            to: url(user, "/media", new URLSearchParams({ tab: "generate" })),
            icon: <Flame className="mr-2 h-4 w-4" />,
          },
          ["New Concept"]: {
            to: url(user, "/concepts", new URLSearchParams({ create: "true" })),
            icon: <Lightbulb className="mr-2 h-4 w-4" />,
          },
        },
      ],
    },
    Plan: {
      icon: <GanttChart className="mr-2 h-4 w-4" />,
      isActive: (pathname) => {
        const cleanPath = removeHubPrefix(pathname);
        return ["/calendar", "/campaigns", "/drafts"].includes(cleanPath);
      },
      routes: [
        {
          Calendar: {
            to: url(user, "/calendar"),
            icon: <CalendarRange className="mr-2 h-4 w-4" />,
          },
          ...(!isHubUser && {
            Campaigns: {
              to: url(user, "/campaigns"),
              icon: <Megaphone className="mr-2 h-4 w-4" />,
            },
          }),
          Drafts: {
            icon: <FileText className="mr-2 h-4 w-4" />,
            to: url(user, "/drafts"),
          },
        },
      ],
    },
    ...(!isHubUser && {
      Engage: {
        icon: <HeartHandshake className="mr-2 h-4 w-4" />,
        isActive: (pathname) => {
          const cleanPath = removeHubPrefix(pathname);
          return ["/engage", "/posts", "/inbox"].includes(cleanPath);
        },
        routes: [
          {
            ...(user?.workspace?.flags?.includes("cm") && {
              ["Unified Inbox"]: {
                icon: <Inbox className="mr-2 h-4 w-4" />,
                to: url(user, "/engage"),
                badge: "EXPERIMENTAL",
                badgeClassName:
                  "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
              },
            }),
            Posts: {
              to: url(user, "/posts"),
              icon: <BadgeCheck className="mr-2 h-4 w-4" />,
            },
            ...(user.manifest?.inbox && {
              Messages: {
                to: url(user, "/inbox"),
                icon: <MessagesSquare className="mr-2 h-4 w-4" />,
                badge: "EXPERIMENTAL",
                badgeClassName:
                  "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
              },
            }),
          },
        ],
      },
    }),
    Analyze: {
      icon: <BarChart className="mr-2 h-4 w-4" />,
      isActive: (pathname) =>
        removeHubPrefix(pathname).startsWith("/analytics"),
      routes: [
        {
          Overview: {
            to: url(user, "/analytics", searchParams),
            icon: <LayoutDashboard className="mr-2 h-4 w-4" />,
          },
          ...(isHubUser && {
            ["By Location"]: {
              to: url(user, "/analytics/locations", searchParams),
              icon: <MapPinned className="mr-2 h-4 w-4" />,
              badge: "NEW",
              badgeClassName: "bg-green-50 text-green-700 ring-green-600/20",
            },
          }),
          ["Content Insights"]: {
            to: url(user, "/analytics/content", searchParams),
            icon: <PieChart className="mr-2 h-4 w-4" />,
            badge: "NEW",
            badgeClassName: "bg-green-50 text-green-700 ring-green-600/20",
          },
          ...(primaryHubId && {
            Leaderboard: {
              to: url(user, "/analytics/leaderboard", searchParams),
              icon: <Trophy className="mr-2 h-4 w-4" />,
              badge: "BETA",
              badgeClassName: "bg-blue-50 text-blue-700 ring-blue-600/20",
            },
          }),
        },
      ],
    },
    Manage: {
      icon: <Settings2 className="mr-2 h-4 w-4" />,
      isActive: (pathname) => {
        const cleanPath = removeHubPrefix(pathname);
        return [
          "/organization",
          "/socials",
          "/integrations",
          "/brand",
          "/locations",
          "/settings",
        ].some((path) => cleanPath.startsWith(path));
      },
      routes: [
        {
          ["Brand Kit"]: {
            to: url(user, "/brand"),
            icon: <Palette className="mr-2 h-4 w-4" />,
            badge:
              !isHubUser &&
              (user?.workspace?.primaryHub ??
              Boolean(user?.workspace?.hubs?.length)
                ? "HUB MANAGED"
                : "NEW"),
            badgeClassName:
              user?.workspace?.primaryHub ??
              Boolean(user?.workspace?.hubs?.length)
                ? "bg-red-50 text-red-700 ring-red-600/20"
                : "bg-green-50 text-green-700 ring-green-600/20",
          },
          ...(isHubUser
            ? {
                Locations: {
                  to: url(user, "/locations"),
                  icon: <MapPin className="mr-2 h-4 w-4" />,
                },
                Settings: {
                  to: url(user, "/settings"),
                  icon: <Settings className="mr-2 h-4 w-4" />,
                },
              }
            : !user?.embed && {
                Organization: {
                  to: url(user, "/organization"),
                  icon: <Building2 className="mr-2 h-4 w-4" />,
                },
                ["Social Accounts"]: {
                  to: url(user, "/socials"),
                  icon: <UsersRound className="mr-2 h-4 w-4" />,
                },
                Integrations: {
                  to: url(user, "/integrations"),
                  icon: <Cable className="mr-2 h-4 w-4" />,
                },
              }),
        },
      ],
    },
  };

  const filterChildrenRoutes = (
    routes: Record<string, MainRoute>,
    disableKeys: string[],
  ) => {
    return Object.fromEntries(
      Object.entries(routes)
        .map(([key, route]) => {
          if (route.routes) {
            return [
              key,
              {
                ...route,
                routes: route.routes.map((subRoute) =>
                  Object.fromEntries(
                    Object.entries(subRoute).filter(
                      ([k]) => !disableKeys.includes(k),
                    ),
                  ),
                ),
              },
            ];
          }
          return !disableKeys.includes(key) ? [key, route] : [];
        })
        .filter((entry) => entry.length),
    );
  };

  const paidRoutes: Record<string, MainRoute> = {
    Home: {
      icon: <Home className="mr-2 h-4 w-4" />,
      isActive: (pathname) => {
        const cleanPath = removeHubPrefix(pathname);
        return cleanPath.startsWith("/paid");
      },
      to: url(user, "/paid"),
    },
    ...filterChildrenRoutes(ORGANIZE_MENU, ["Concepts", "Templates"]),
    Manage: {
      icon: <Settings2 className="mr-2 h-4 w-4" />,
      isActive: (pathname) => {
        const cleanPath = removeHubPrefix(pathname);
        return [
          "/organization",
          "/socials",
          "/integrations",
          "/brand",
          "/locations",
          "/settings",
        ].some((path) => cleanPath.startsWith(path));
      },
      routes: [
        {
          ["Brand Kit"]: {
            to: url(user, "/brand"),
            icon: <Palette className="mr-2 h-4 w-4" />,
            badge:
              !isHubUser &&
              (user?.workspace?.primaryHub ??
              Boolean(user?.workspace?.hubs?.length)
                ? "HUB MANAGED"
                : "NEW"),
            badgeClassName:
              user?.workspace?.primaryHub ??
              Boolean(user?.workspace?.hubs?.length)
                ? "bg-red-50 text-red-700 ring-red-600/20"
                : "bg-green-50 text-green-700 ring-green-600/20",
          },
          ...(isHubUser
            ? {
                Locations: {
                  to: url(user, "/locations"),
                  icon: <MapPin className="mr-2 h-4 w-4" />,
                },
                Settings: {
                  to: url(user, "/settings"),
                  icon: <Settings className="mr-2 h-4 w-4" />,
                },
              }
            : !user?.embed && {
                Organization: {
                  to: url(user, "/organization"),
                  icon: <Building2 className="mr-2 h-4 w-4" />,
                },
                ["Social Accounts"]: {
                  to: url(user, "/socials"),
                  icon: <UsersRound className="mr-2 h-4 w-4" />,
                },
                Integrations: {
                  to: url(user, "/integrations"),
                  icon: <Cable className="mr-2 h-4 w-4" />,
                },
              }),
        },
      ],
    },
  };

  switch (user.module) {
    case Module.Paid:
      return paidRoutes;
    default:
      return organicRoutes;
  }
};

export default Main;
