import axios from "axios";
import { z } from "zod";
import { ObjectValues } from "../../../util/reusables";
import { CreateAdCreativeData } from "./adcreatives.api";
import { MetaAdLabel } from "./adlabels.api";

export const PaidAdStatus = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  DELETED: "DELETED",
  ARCHIVED: "ARCHIVED",
} as const;
export type PaidAdStatus = ObjectValues<typeof PaidAdStatus>;

export const IMetaAd = z.object({
  id: z.string().describe("Ad's ID"),
  name: z.string().describe("Ad's name"),
  status: z
    .enum(Object.values(PaidAdStatus) as [string, ...string[]])
    .describe("Ad status"),
  adset_id: z.string().describe("ID of the ad set that contains this ad"),
  creative: z
    .object({
      creative_id: z
        .string()
        .min(1, "Please select an ad creative")
        .describe("Creative ID on creation"),
      id: z.string().optional().describe("Creative ID on read"),
      // Add more fields as needed for the creative
    })
    .describe("Creative associated with this ad"),

  // Optional fields
  adlabels: z
    .array(MetaAdLabel)
    .optional()
    .describe("Ad Labels associated with this ad"),
  bid_amount: z.number().optional().describe("Bid amount for this ad"),
  configured_status: z
    .enum(Object.values(PaidAdStatus) as [string, ...string[]])
    .optional()
    .describe("The configured status of this ad"),
  effective_status: z
    .enum([
      "ACTIVE",
      "PAUSED",
      "DELETED",
      "PENDING_REVIEW",
      "DISAPPROVED",
      "PREAPPROVED",
      "PENDING_BILLING_INFO",
      "CAMPAIGN_PAUSED",
      "ARCHIVED",
      "ADSET_PAUSED",
      "IN_PROCESS",
      "WITH_ISSUES",
    ])
    .optional()
    .describe("The effective status of this ad"),
  created_time: z.string().datetime().optional().describe("Created time"),
  updated_time: z.string().datetime().optional().describe("Updated time"),
  campaign_id: z
    .string()
    .optional()
    .describe("ID of the campaign that contains this ad"),
  targeting: z.any().optional().describe("Targeting specs for this ad"),
  tracking_specs: z
    .array(z.any())
    .optional()
    .describe("Tracking specs for this ad"),
  isParent: z.boolean().optional().describe("Whether this is a parent ad"),
  children: z.array(z.string()).optional().describe("Children ad IDs"),
});

export type IMetaAd = z.infer<typeof IMetaAd> & {
  _id?: string;
  hub?: string;
  team?: string;
  parent?: string;
  metaErrors?: any[];
};

export const CreateAdSchema = IMetaAd.pick({
  name: true,
  status: true,
  adset_id: true,
  // creative: true,
  campaign_id: true,
  isParent: true,
  adlabels: true,
}).extend({
  name: z.string().min(1, "Ad name is required"),
});

export type CreateAdData = z.infer<typeof CreateAdSchema>;
export const createPaidAd = async (
  adData: CreateAdData,
  adCreativeData?: CreateAdCreativeData,
  mediaIds?: string[],
): Promise<{
  ad: IMetaAd;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    ad: IMetaAd;
    successCount?: number;
    errorCount?: number;
  }>("/api/paid/ads", { adData, adCreativeData, mediaIds });
  return response.data;
};

export const retryPaidAd = async (
  _id: string,
): Promise<{
  ad: IMetaAd;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    ad: IMetaAd;
    successCount?: number;
    errorCount?: number;
  }>(`/api/paid/ads/${_id}/retry`);
  return response.data;
};

export const updatePaidAdStatus = async (
  adId: string,
  on: boolean,
  shouldReturnParent?: boolean,
): Promise<IMetaAd> => {
  const response = await axios.patch<IMetaAd>(`/api/paid/ads/${adId}/status`, {
    on,
    shouldReturnParent,
  });
  return response.data;
};

export const updatePaidAd = async (
  adId: string,
  updateData: Partial<CreateAdData>,
): Promise<IMetaAd> => {
  const response = await axios.patch<IMetaAd>(
    `/api/paid/ads/${adId}`,
    updateData,
  );
  return response.data;
};

export const getPaidAd = async (adId: string): Promise<IMetaAd> => {
  const response = await axios.get<IMetaAd>(`/api/paid/ads/${adId}`);
  return response.data;
};

export const listPaidAdsForAdset = async (
  adsetId: string,
): Promise<IMetaAd[]> => {
  const response = await axios.get<IMetaAd[]>(`/api/paid/ads/adset/${adsetId}`);
  return response.data;
};

export const deletePaidAd = async (
  adId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/ads/${adId}`,
  );
  return response.data;
};

export const syncPaidAdsForAdset = async (adsetId?: string): Promise<void> => {
  const response = await axios.post<void>(`/api/paid/ads/sync/${adsetId}`);
  return response.data;
};
