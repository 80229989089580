import axios from "axios";
import { z } from "zod";

const OperationStatus = z.object({
  code: z.number().describe("Status code of the operation"),
  description: z.string().describe("Description of the operation status"),
});

const PermissionForActions = z.object({
  can_edit: z.boolean().describe("Whether the audience can be edited"),
  can_see_insight: z.boolean().describe("Whether insights can be viewed"),
  can_share: z.boolean().describe("Whether the audience can be shared"),
  subtype_supports_lookalike: z
    .boolean()
    .describe("Whether lookalike audiences can be created"),
  supports_recipient_lookalike: z
    .boolean()
    .describe("Whether recipient lookalike audiences are supported"),
});

export const IMetaAudience = z.object({
  _id: z.string().describe("MongoDB document ID"),
  id: z.string().describe("Meta Custom Audience ID"),
  name: z.string().describe("Name of the audience"),
  description: z.string().optional().describe("Description of the audience"),
  subtype: z.string().optional().describe("Subtype of the custom audience"),
  approximate_count_lower_bound: z
    .number()
    .optional()
    .describe("Minimum estimated audience size"),
  approximate_count_upper_bound: z
    .number()
    .optional()
    .describe("Maximum estimated audience size"),
  time_created: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was created on Meta"),
  time_updated: z
    .string()
    .datetime()
    .optional()
    .describe("When the audience was last updated on Meta"),
  createdAt: z
    .string()
    .datetime()
    .optional()
    .describe("When the document was created in our database"),
  updatedAt: z
    .string()
    .datetime()
    .optional()
    .describe("When the document was last updated in our database"),
  customer_file_source: z
    .string()
    .optional()
    .describe("Source of the customer data"),
  retention_days: z
    .number()
    .optional()
    .describe("Days to retain audience data"),
  rule: z.string().optional().describe("Rule for dynamic audience creation"),
  pixel_id: z.string().optional().describe("Associated Meta Pixel ID"),
  team: z.string().optional().describe("Associated team ID"),
  hub: z.string().optional().describe("Associated hub ID"),
  account_id: z.string().optional().describe("Associated Meta Ad Account ID"),
  operation_status: OperationStatus.optional().describe(
    "Current operational status",
  ),
  permission_for_actions: PermissionForActions.optional().describe(
    "Available actions and permissions",
  ),
  exclusions: z.array(z.unknown()).optional().describe("Excluded audiences"),
  inclusions: z.array(z.unknown()).optional().describe("Included audiences"),
});

export type IMetaAudience = z.infer<typeof IMetaAudience>;

export const CreateCustomAudienceSchema = IMetaAudience.pick({
  name: true,
  description: true,
  subtype: true,
  customer_file_source: true,
  retention_days: true,
  rule: true,
  pixel_id: true,
}).extend({
  name: z.string().min(1, "Audience name is required"),
  subtype: z.string().min(1, "Audience subtype is required"),
});

export type CreateCustomAudienceData = z.infer<
  typeof CreateCustomAudienceSchema
>;

export const createPaidAudience = async (
  audienceData: CreateCustomAudienceData,
): Promise<IMetaAudience> => {
  const response = await axios.post<IMetaAudience>(
    "/api/paid/audiences",
    audienceData,
  );
  return response.data;
};

export const updatePaidAudience = async (
  audienceId: string,
  updateData: Partial<CreateCustomAudienceData>,
): Promise<IMetaAudience> => {
  const response = await axios.patch<IMetaAudience>(
    `/api/paid/audiences/${audienceId}`,
    updateData,
  );
  return response.data;
};

export const getPaidAudience = async (
  audienceId: string,
): Promise<IMetaAudience> => {
  const response = await axios.get<IMetaAudience>(
    `/api/paid/audiences/${audienceId}`,
  );
  return response.data;
};

export const listPaidAudiences = async (): Promise<IMetaAudience[]> => {
  const response = await axios.get<IMetaAudience[]>("/api/paid/audiences");
  return response.data;
};

export const deletePaidAudience = async (
  audienceId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/audiences/${audienceId}`,
  );
  return response.data;
};

export const syncPaidAudiences = async (): Promise<{
  message: string;
  matchedCount: number;
  modifiedCount: number;
  upsertedCount: number;
}> => {
  const response = await axios.post<{
    message: string;
    matchedCount: number;
    modifiedCount: number;
    upsertedCount: number;
  }>("/api/paid/audiences/sync");
  return response.data;
};
